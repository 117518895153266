import Styles.CMNT_STYLES
import Styles.DFLT_STYLES
import Styles.KW_STYLES
import Styles.STRING_STYLES
import Styles.VAR_STYLES
import builder.codeBlock
import codewriter.CodeWriter
import codewriter.ContainerManager
import model.CodeSequenceStyle
import model.StyleSet
import model.WeightValue
import org.w3c.dom.HTMLDivElement
import kotlin.browser.document
import kotlin.js.Date
import kotlinx.coroutines.GlobalScope

/**
 * Created by Julian Kotrba on 07.02.18.
 */

fun main(args: Array<String>) {
    dslCode()
}

fun dslCode() {

    val c =
            codeBlock {
                codeLine {
                    codeSequence {
                        text = "// "
                        styleSet = CMNT_STYLES
                    }

                    codeSequence {
                        text = "Person.kt"
                        styleSet = STRING_STYLES
                    }
                }
                codeLine {
                    codeSequence {
                        text = "data"
                        styleSet = KW_STYLES
                    }
                    codeSequence {
                        text = " class"
                        styleSet = KW_STYLES
                    }
                    codeSequence(" Person(", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1) {
                        text = "val"
                        styleSet = KW_STYLES
                    }

                    codeSequence(" name", VAR_STYLES)
                    codeSequence(": String = ", DFLT_STYLES, "breakable")
                    codeSequence("\"Julian Kotrba\"", STRING_STYLES, "cssTab")
                    codeSequence(",", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1) {
                        text = "val"
                        styleSet = KW_STYLES
                    }
                    codeSequence(" ageInMillis", VAR_STYLES)
                    codeSequence(": () -> Long = {", DFLT_STYLES, "breakable")
                }
                codeLine {
                    codeSequenceWithTabs(2, "java.util.Date().getTime() - ", DFLT_STYLES)
                    codeSequence("718326000000", STRING_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1, "},", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1, "val", KW_STYLES)
                    codeSequence(" livingIn", VAR_STYLES)
                    codeSequence(": String = ", DFLT_STYLES, "breakable")
                    codeSequence(livingInText(), STRING_STYLES, "cssTab")
                    codeSequence(",", DFLT_STYLES)
                }
                codeLine {
                    codeSequence("    val", KW_STYLES)
                    codeSequence(" degrees", VAR_STYLES)
                    codeSequence(": Map<", DFLT_STYLES)
                    codeSequence("Deg", DFLT_STYLES, "unresolved")
                    codeSequence(", String> = mapOf(", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(2, "", DFLT_STYLES)
                    codeSequence("Deg", DFLT_STYLES, "unresolved")
                    codeSequence(".", DFLT_STYLES)
                    codeSequence("BSc", VAR_STYLES)
                    codeSequence(" to ", DFLT_STYLES)
                    codeSequence("\"Software & Information Engr.\"", STRING_STYLES)
                    codeSequence(",", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(2, "", DFLT_STYLES)
                    codeSequence("Deg", DFLT_STYLES, "unresolved")
                    codeSequence(".", DFLT_STYLES)
                    codeSequence("Dipl_Ing", VAR_STYLES)
                    codeSequence(" to ", DFLT_STYLES)
                    codeSequence("\"Software Engr. & Internet Comp.\"", STRING_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1, "),", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1, "val", KW_STYLES)
                    codeSequence(" study", VAR_STYLES)
                    codeSequence(": String? = ", DFLT_STYLES, "breakable")
                    codeSequence("null", DFLT_STYLES, "cssTab")
                    codeSequence(",", DFLT_STYLES)
                }
                codeLine {
                    codeSequenceWithTabs(1, "val", KW_STYLES)
                    codeSequence(" work", VAR_STYLES)
                    codeSequence(": String? = ", DFLT_STYLES, "breakable")
                    codeSequence("\"Android Developer @ REWE\"", STRING_STYLES, "cssTab")
                }
                codeLine {
                    codeSequence(")", DFLT_STYLES)
                }
            }

    val delayGenFunc = { listOf(50, 50, 50, 50, 50, 50, 80, 150, 200).shuffled().first() }
    val divContainer = document.getElementById("code_container") as HTMLDivElement

    val cw = CodeWriter(GlobalScope, ContainerManager(divContainer, delayGenFunc))
    cw.write(c)

}

private object Styles {

    private const val DEFAULT_COLOR = "#E8EAF6"
    private const val KEYWORD_COLOR = "#2196F3"
    private const val COMMENT_COLOR = "#00E676"
    private const val VARIABLE_COLOR = "#FF9800"
    private const val STRING_COLOR = "#00E676"

    val DFLT_STYLES = StyleSet(CodeSequenceStyle(DEFAULT_COLOR), CodeSequenceStyle(DEFAULT_COLOR))
    val KW_STYLES = StyleSet(CodeSequenceStyle(DEFAULT_COLOR), CodeSequenceStyle(KEYWORD_COLOR, WeightValue.BOLD))
    val CMNT_STYLES = StyleSet(CodeSequenceStyle(DEFAULT_COLOR), CodeSequenceStyle(COMMENT_COLOR))
    val VAR_STYLES = StyleSet(CodeSequenceStyle(VARIABLE_COLOR), CodeSequenceStyle(VARIABLE_COLOR))
    val STRING_STYLES = StyleSet(CodeSequenceStyle(STRING_COLOR), CodeSequenceStyle(STRING_COLOR))
    val ENUM_CONSTANT_STYLES = StyleSet(CodeSequenceStyle(DEFAULT_COLOR), CodeSequenceStyle(VARIABLE_COLOR))
}

data class Person(
        val name: String = "Julian Kotrba",
        val ageInMilli: () -> Long = {
            Date().getTime().toLong() - 718326000000L
        },
        val livingIn: String = "Salzburg",
        val degrees: MutableMap<Deg, String> = mutableMapOf(
                Deg.BSc to "Software & Information Engr.",
                Deg.Dipl_Ing to "Software Engr. & Internet Comp."
        ),
        val currStudy: String? = null,
        var currWork: String? = "Android Developer @ REWE"
)

fun livingInText() = "\"Salzburg\""

enum class Deg {
    BSc,
    Dipl_Ing
}